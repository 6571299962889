export const filteredData = (data) => {
  let returnArray = data?.sort((a, b) => {
    // Convert 'us_id' to numbers and compare them for sorting.
    return parseInt(b.us_id || b.id) - parseInt(a.us_id || a.id);
  })
  return returnArray;
}
export const removeSpecialCharacters = (inputString) => {
  return inputString.replace(/[^\w\s]/g, '');
}
export const removeDuplicatesFromString = (string) => {
  const namesAndEmailsString = string;

  // Split the string into an array
  const namesAndEmailsArray = namesAndEmailsString.split(',');

  // Remove duplicates
  const uniqueNamesAndEmailsArray = [...new Set(namesAndEmailsArray)];

  // Join the unique array back into a string
  const uniqueNamesAndEmailsString = uniqueNamesAndEmailsArray.join(',');

  return uniqueNamesAndEmailsString;

}

export const getCompleteData = (array1, array2, getUser) => {

  let currentGroupTasksData = array2?.filter(item => {
    return item.main_task_id !== '0' && item.assignedTo === getUser?.user?.empId && item.story_type === 'group'
  }) || []

  let currentGroupTasksSelfData = array2?.filter(item => {
    return item.main_task_id === '0' && item.assignedTo === getUser?.user?.empId && item.story_type === 'group'
  }) || []

  let currentPendingTasks = array1 || []

  let currentGroupTasks = currentPendingTasks.concat(currentGroupTasksData)

  return currentGroupTasks.concat(currentGroupTasksSelfData)
}

export const getCompleteRoadblockData = (array1, array2, getUser) => {

  let currentGroupTasksData = array2?.filter(item => {
    return item.main_task_id !== '0' && item.metadata.assigned_to === getUser?.user?.empId && item.metadata.story_type === 'group'
  }) || []

  let currentGroupTasksSelfData = array2?.filter(item => {
    return item.main_task_id === '0' && item.metadata.assigned_to === getUser?.user?.empId && item.metadata.story_type === 'group'
  }) || []

  let currentPendingTasks = array1 || []

  let currentGroupTasks = currentPendingTasks.concat(currentGroupTasksData)

  return currentGroupTasks.concat(currentGroupTasksSelfData)
}
export const capitalizeFirstLetter = (str) => {

  if (str === null || str === "" || str === undefined) {
    return ""; // Return an empty string for null or empty input
  }

  if (str.charAt(0) === ' ') {
    // If the first character is a space, return the string without capitalizing it
    return str;
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const isValidEmail = (email) => {
  // Regular expression for basic email validation
  var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

export const getEmailDomain = (email) => {
  const parts = email.split("@");
  return parts[1];
}