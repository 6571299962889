import Alert from "../../Alert";
import API from "../../Network/API";
import {isLoaded, isLoading } from "../action";
import { getGroupDetails } from "./getGroupDetails";

// for exit group
export async function exitGroupChat(state, dispatch, newMem, newLabel, getUser, id, handleClose) {
    dispatch(isLoading());
    try {
      var response = await API.post("group_chat.php", {
        crop: getUser.corp,
        newMem: newMem,
        newLabel: newLabel,
        action: "exitGroup",
        created_by: getUser.userName,
        group_Id: id,
      }, {}, false);
      if (response.status === 'True') {
        getGroupDetails(dispatch, getUser)
        // Alert("success", "Successfully Exited!");
      }
    } catch (error) {
      Alert('error', error.message);
      dispatch(isLoaded());
    }
    handleClose()
}