import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import { isLoaded, isLoading } from "../actions";
import { addCommentUserStory } from "./addCommentUserStory";
import { getCurrentSprintUserStoriesToKanban } from "./getCurrentSprintUserStoriesToKanban";

//Update the Task Status
export async function updateUserStoryToKanban(
    dispatch,
    getUser,
    userStoryId,
    sprintId,
    handleClose, message, TASKS_WARNING, ROADBLOCK_WARNING
  ) {
    dispatch(isLoading());
    try {
      const response = await API.post(
        "manage_userstories.php",
        {
          action: "update",
          task_id: userStoryId,
          crop: getUser.corp,
          task_status: 100,
          task_status_desc: "completed",
          task_complete_status: 1,
          empId: getUser.empId,
          projectId: getUser.projectId,
        },
        {},
        false
      );
      if (response.status === "True") {
        getCurrentSprintUserStoriesToKanban(dispatch, getUser, sprintId);
        addCommentUserStory(dispatch, getUser, userStoryId, message, "2");
        // Alert('success', "")
      } else {
        const warning = response.type === "tasks" ? TASKS_WARNING : ROADBLOCK_WARNING;
        Alert('warning', warning) 
      }
    } catch (error) {
      Alert("error", error.message);
    }
    dispatch(isLoaded());
    handleClose();
  }
  