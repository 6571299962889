import {
    isLoaded,
    isLoading,
  } from "../actions";
import API from "../../../Common/Network/API";
import { getCurrentSprintUserStories } from "./getCurrentSprintUserStories";
import { addCommentUserStory } from "./addCommentUserStory";
import Alert from "../../../Common/Alert";

export async function updateUserstotyStaus(state, dispatch, getUser, todoStoryId, inProgressStoryId, moduleId, assignedTo, handleClose, MAINTASK) {
    // console.log(todoStoryId+'\n'+inProgressStoryId+'\n'+moduleId)
    dispatch(isLoading());
    const message = `${MAINTASK} in progress`;
    const message1 = `${MAINTASK} moved into To Do`;
    try {
      var response = await API.post(
        "manage_userstories.php",
        {
          crop: getUser.corp,
          empId: getUser.empId,
          todoStoryId: todoStoryId,
          inprogressStoryId: inProgressStoryId,
          action: "change_userstory_status",
          assignedTo: assignedTo
        },
        {},
        false
      );
      if (response.status === "true") {
        getCurrentSprintUserStories(dispatch, getUser, moduleId);
        if (todoStoryId !== "") { // Added condition to Hide for Moving Tasks from In Progress to To-Do AG-4033
          addCommentUserStory(dispatch, getUser, todoStoryId, message, "1");
        }
        addCommentUserStory(dispatch, getUser, inProgressStoryId, message1, "10");
        handleClose();
  
      } else {
        Alert("warning", response.message);
      }
    } catch (error) {
      Alert("warning", error);
    }
  
    dispatch(isLoaded());
    handleClose()
  
  }