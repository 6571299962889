import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import { Client_URL } from "../../../Common/config";
import { isLoaded, isLoading } from "../actions";

export async function deleteEmployee(dispatch, getUser, empId, email, handleClose, handleModalClose, APP_NAME) {
    dispatch(isLoading());
    try {
      var response = await API.post("manage_user.php", {
        "corp": getUser.corp,
        "action": 'delete',
        "empId": empId,
        "projectId":getUser.projectId,
        url: Client_URL,
        appName: APP_NAME,
      }, {}, false);
      if (response.status === 'True') {
        // deActivateEmployee(dispatch, getUser, email, handleClose, handleModalClose) //De Activate the Employee -->GNK -01
        Alert("success", 'Employee is Deleted')
        handleClose()
      }
      else {
        handleClose()
      }
    } catch (error) {
      handleClose()
      Alert('error', error.message);
    }
    dispatch(isLoaded());
  }