import {  isLoaded, isLoading } from "../actions";
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";

export async function deleteSubTask(dispatch, getUser, subtaskId, mainTaskId,priority, handleClose, handleModalClose, SUBTASK_DELETE, SUBTASK) 
{
  dispatch(isLoading());
  try { //manageSubtasks.php
    var response = await API.post("manage_tasks.php", {
      "crop": getUser.corp,
      action: 'deletesubtask',
      subtaskId: subtaskId,
      maintaskId: mainTaskId,
      "projectId":getUser.projectId,
      empId: getUser.empId,
      priority: priority,
    }, {}, false);
    if (response.status === 'true') {
      Alert("success", SUBTASK_DELETE)
      handleClose()
    } else if (response.status === "false") {
      handleModalClose()
      Alert("warning", `You cannot remove a ${SUBTASK} having roadblocks`)
    }
  } catch (error) {
    Alert('error', error.message);
    handleClose()
  }
  dispatch(isLoaded());
}