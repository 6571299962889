import Alert from "../../Alert";

import API from "../../Network/API";
import { isLoaded, isLoading  } from "../action";

//Get PUser Thanks Points
export async function awardThanksPoints(state,dispatch, getUser,empId,handleClose) {
  // console.log(state,empId,getUser.empId)
  dispatch(isLoading());
  try {
    var response = await API.post("usersAwards.php", {
      "crop": getUser.corp,
      awardedTo: empId,
      awardedBy:getUser.empId,
      thanksPointId:state.awardSelected.value,
      description:state.awardDescription.value,
      subtaskId:"NA",
      action: 'userawards',
    }, {}, false);
    // console.log(response)
    if (response.status === 'True') {
      Alert('success',"Awards Presented Successfully")
    }
    else {
    }
    handleClose()
  } catch (error) {
    Alert('error', error.message);
  }
  dispatch(isLoaded());
}