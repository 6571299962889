import { isLoaded, isLoading } from "../actions";
import { addCommentUserStory } from "./addCommentUserStory";
import Alert from "../../Alert";
import API from "../../Network/API";

//For Reassign MainTask
export async function reassignMainTask(backlogs,activeStatus,currentDate,sprintTargetDate,state, dispatch, getUser, ideaId, moduleId, handleClose, MODULE) {
    dispatch(isLoading());
    if(!state.targetDate.value){
      dispatch(isLoaded());
      Alert('error', 'Select Target date');
    }
    else if (state.targetDate.value < currentDate) {
      dispatch(isLoaded());
      Alert('error', 'Select valid date');
  
    }
    else if(state.targetDate.value  > sprintTargetDate && backlogs === '1'){
      dispatch(isLoaded());
      Alert('error', `Target Date should not exceed ${MODULE} target date`);
    }
   else if (state.taskTitle.value !== "" && state.taskDescription.value !== "" && state.acceptanceCriteria.value !== "") {
      try {
        var response = await API.post("manage_userstories.php", {
          crop: getUser.corp,
          action: "reassign",
          title: state.taskTitle.value,
          description: state.taskDescription.value,
          acceptanceCriteria: state.acceptanceCriteria.value,
          module_id: state.moduleId.value,
          idea_id: state.epicSelected === "" ? state.ideaId.value : state.epicSelected,
          added_to: state.userSelected === "" ? state.id.value : state.userSelected,
          added_by: getUser.empId,
          mainTaskId: state.taskId.value,
          storyPoints: state.storySelected === "" ? state.storyPoints.value : state.storySelected,
          priorityLevel: state.prioritySelected === "" ? state.priorityLevel.value : state.prioritySelected,
          "projectId":getUser.projectId,
          targetDate: state.targetDate.value,
          device_id: state.device_id,
          player_id: state.player_id,
  
        }, {}, false);
        if (response.status === 'True') {
          addCommentUserStory(dispatch, getUser, state.taskId.value, "User story Reassigned", "5")
          // dispatch(getModuleMainTasks(dispatch, getUser, moduleId))
          // Alert("success", MAINTASK_REASSIGN);
        }
      } catch (error) {
        Alert('error', error.message);
        dispatch(isLoaded());
      }
      handleClose()
    } else {
      Alert("warning", "please give all the details")
    }
  }