import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import { isLoaded, isLoading, allMessages } from "../action";

export async function getAllGroupMessages(dispatch, getUser,refresh) {
  if(refresh) {dispatch(isLoading());}
  try {
    var response = await API.post(
      "group_chat.php",
      {
        corp_code: getUser.corp,
        action: "getAllGroupMessages",
        created_by: getUser.userName,
        "userType": getUser.role,
        "empId": getUser.empId
      },
      {},
      false
    );
    // console.log(response.data)
    if (response.status === "True") {
      dispatch(allMessages(response.data));
      // store.dispatch(allMessages(response.data));
    } else {
      dispatch(allMessages([]));
    }
  } catch (error) {
    Alert("error", error.message);
  }
  dispatch(isLoaded());
}