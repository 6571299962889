//accounts/service/getEmployee( READ)
import {  employees, isLoaded, isLoading } from "../actions";
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";


export async function getEmployees(dispatch, getUser) {
    const [, extension] = (getUser.userName).split('@')
  
    dispatch(isLoading());
    // console.log(getUser.role)
    try {
      var response = await API.post("accounts.php", {
        "crop": getUser.corp,
        "userType": getUser.role,
        "empId": getUser.empId,
        "extension": extension,
        action:'get_accounts'
      }, {}, false);
      if (response.status === 'True') {
        dispatch(employees(response.data))
      }
      else {
        dispatch(employees([]))
      }
    } catch (error) {
      Alert('error', error.message);
    }
    dispatch(isLoaded());
  }