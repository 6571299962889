import { isLoaded, isLoading } from "../actions";
import { addCommentUserStory } from "./addCommentUserStory";
import Alert from "../../Alert";
import API from "../../Network/API";

//For Add MainTask To Kanban
export async function addMainTaskToKanban(sprintData,state, dispatch, getUser,handleClose, MAINTASK_ADD, MAINTASK, KANBAN) {
    dispatch(isLoading());
    if (state.taskTitle.value.trim() !== "" && state.taskDescription.value.trim() !== "" && state.acceptanceCriteria.value.trim() !== "") {
      const message = `This ${MAINTASK} added in ${KANBAN}`
  
      try {
        var response = await API.post("manage_userstories.php", {
          crop: getUser.corp,
          module_id:sprintData.moduleId,
          action: "addMainTaskToKanban",
          title: state.taskTitle.value,
          description: state.taskDescription.value,
          added_to: state.userSelected,
          added_by: getUser.empId,
          acceptanceCriteria: state.acceptanceCriteria.value,
          storyPoints: state.storySelected === "" ? '0' : state.storySelected,
          priorityLevel: state.prioritySelected === "" ? '0' : state.prioritySelected,
          idea_id: state.epicSelected,
          "projectId":getUser.projectId,
          device_id: state.device_id,
          player_id: state.player_id,
  
        }, {}, false);
        if (response.status === 'True') {
          let taskId=response.taskId;
          addCommentUserStory(dispatch, getUser, taskId, message, "11");
          Alert("success", MAINTASK_ADD);
        }
        else{
          alert(`something went wrong, ${MAINTASK} is already present`)
        }
      } catch (error) {
        Alert('error', error.message);
        dispatch(isLoaded());
      }
      handleClose()
    }
   
    else {
      Alert("warning", "Please enter required fields ")
      dispatch(isLoaded());
    }
  }