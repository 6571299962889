import { isLoaded, isLoading } from "../actions";
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import { addCommentUserStory } from "../../Modules/Services/addCommentUserStory";

export async function removeFromSprint(taskId, sprintId, state, dispatch, getUser, handleClose, sprintDesc, MAINTASK, story_us_id, story_type, main_task_id) {
  console.log(taskId, sprintId, state, dispatch, getUser, handleClose, sprintDesc, MAINTASK, story_us_id, story_type, main_task_id)
  if (story_type === 'group' && main_task_id !== '0') {
    Alert('warning', 'Group Task cannot be moved individually into timeline');
  } else {
    const message = `This ${MAINTASK} removed from sprint ${sprintDesc}`

    dispatch(isLoading());

    try {
      var response = await API.post("getUpdateSprint.php", {
        crop: getUser.corp,
        action: "remove_from_sprint",
        sprintId: sprintId,
        userStoryId: taskId,
        "projectId": getUser.projectId
      }, {}, false);
      if (response.status === 'True') {
        if (story_type === 'group' && main_task_id === '0') {
          getGroupTaskInfo(taskId, sprintId, state, dispatch, getUser, handleClose, sprintDesc, MAINTASK, story_us_id, story_type, main_task_id)
        }
        addCommentUserStory(dispatch, getUser, taskId, message, "11");
        Alert("success", "UPDATED");
      }
    } catch (error) {
      Alert('error', error.message);
      dispatch(isLoaded());
    }
    handleClose()
  }
}

async function getGroupTaskInfo(taskId, sprintId, state, dispatch, getUser, handleClose, sprintDesc, MAINTASK, story_us_id, story_type, main_task_id) {
  try {
    var response = await API.post("getUpdateSprint.php", {
      story_us_id: story_us_id,
      action: "get_group_task_info",
    }, {}, false);
    if (response.status === 'True') {
      console.log(response.data, "im here")
      response.data.map(item => {
        return removeGroupTasks(item.story_id, item.sprint_id, state, dispatch, getUser, handleClose, sprintDesc, MAINTASK, story_us_id, story_type, main_task_id)
      })
    }
  } catch (error) {
    console.log(error)
  }
  handleClose()
}

async function removeGroupTasks(taskId, sprintId, state, dispatch, getUser, handleClose, sprintDesc, MAINTASK, story_us_id, story_type, main_task_id) {
  const message = `This ${MAINTASK} removed from sprint ${sprintDesc}`

  try {
    var response = await API.post("getUpdateSprint.php", {
      crop: getUser.corp,
      action: "remove_from_sprint",
      sprintId: sprintId,
      userStoryId: taskId,
      "projectId": getUser.projectId
    }, {}, false);
    if (response.status === 'True') {
      addCommentUserStory(dispatch, getUser, taskId, message, "11");
    }
  } catch (error) {
    Alert('error', error.message);
  }
  handleClose()
}