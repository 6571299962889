import API from "../../Network/API";
import { isLoading, isLoaded, user } from "../action";
import Alert from '../../Alert';
import { Client_URL } from "../../config";


//to get event
export async function getEvent(getUser,dispatch, APP_NAME) {
  dispatch(isLoading());
  // console.log(getUser.corp)
  try {
    var response = await API.post("calendar.php", {
      action: "GetEvent",
      corp_code: getUser.corp,
      projectId: getUser.projectId,
      "userType": getUser.role,
      userName: getUser.userName,
      url: Client_URL,
      appName: APP_NAME,
  }, {}, false);
  // dispatch(user(response.data));
  // console.log(response)
  if (response.status === 'True') {
    // console.log(response.data)
    dispatch(user(response.data))
  }
  else {
    dispatch(user([]))
  }
  } catch (error) {
      console.log(error)
      Alert('error', error.message);
  }
  dispatch(isLoaded());
}