import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import { employees, isLoaded, isLoading } from "../action";

// for getting all employee details
export async function getEmployees(dispatch, getUser) {
  dispatch(isLoading());
  try {
    var response = await API.post("agile_squads.php", {
      // "crop": getUser.corp,
      // projectId: getUser.projectId,
      "crop": getUser.corp,
    "userType": getUser.role,
    "empId": getUser.empId,
    }, {}, false);
    // console.log(response.data)
    if (response.status === 'True') {
      dispatch(employees(response.data))
    }
    else {
      dispatch(employees([]))
    }
  } catch (error) {
    Alert('error', error.message);
  }
  dispatch(isLoaded());
}