import React, { useEffect, useReducer } from "react";
import { subtasksReducer, initialState } from "../../Common/SubTaskModals/subtaskReducer";
import { getSubTasks } from "../../Common/SubTaskModals/Services/getSubtask";
import { useWebProperties } from "../../Common/webProperties";
import { getSubStringId } from "../../Common/SubStringConvert";
import store from "../../Common/Store";

export default function SubTasksInfo(props) {
    const getUser = store.getState().auth.user;
    const [state, dispatch] = useReducer(subtasksReducer, initialState);
    useEffect(() => {
        getSubTasks(dispatch, getUser, props.data.id)
    }, [getUser, props.data.id]);

    const { SUBTASK, ASSIGNED_TO, ASSIGNED_BY, STATUS } = useWebProperties();

    const subtasks = state.manageSubTasks.filter((o) => o.status === "pending");
    const PendingSubtask = subtasks && subtasks.length > 0 ? subtasks[0] : {};
    const { t_id, taskTitle, assignedBy, assignedTo, status, activeStatus } = PendingSubtask;

    if(!subtasks.length || !state.manageSubTasks.length) {
        return null;
    } 

    return (
        <div>
            <div className="pt-2">
                <text style={{ fontWeight: "bold", fontSize: 12 }}>
                    {SUBTASK} Information:
                </text>
            </div>
            <div className="pt-2">
                <text style={{ fontWeight: "bold", fontSize: 12 }}>
                    Completed {SUBTASK}: ({(state.manageSubTasks.length - subtasks.length)}/{state.manageSubTasks.length})
                </text>
            </div>
            {/* {state.manageSubTasks.filter((o) => o.priorityLevel === "1").map(({ t_id, taskTitle, assignedBy, assignedTo, status }, index) => */}
                <div className="overflow-auto mt-1" style={{ padding: '10px', overflow: 'auto', border: '0.2px solid grey', borderRadius: '10px' }}>
                    <p className="text-success" style={{ fontWeight: 'bold'}}>{getSubStringId(getUser.corp, 3)}-{t_id}-{taskTitle} </p>
                    <p style={{ fontSize: '12px' }}>{ASSIGNED_BY} : {assignedBy} </p>
                    <p style={{ fontSize: '12px' }}>{ASSIGNED_TO} : {assignedTo}</p>
                    <p style={{ fontSize: '12px' }}>{STATUS} : {activeStatus === "1" ? 'In Progress' : status} </p>
                </div>
            {/* )} */}
        </div>
    )

}