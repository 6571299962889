import API from "../../Network/API";
import { isLoading, isLoaded,  events } from "../action";
import Alert from '../../Alert';
import { Client_URL } from "../../config";


//to get event for calear Dash
export async function getEventCalendarDash(getUser,dispatch, APP_NAME) {
  dispatch(isLoading());
  // console.log(getUser.corp)
  try {
    var response = await API.post("calendarDash.php", {
      action: "GetEventCalendarDash",
      // corp_code: getUser.corp,
      // projectId: getUser.projectId,
      userName: getUser.userName,
      url: Client_URL,
      appName: APP_NAME,
  }, {}, false);
  // dispatch(user(response.data));
  // console.log("events",response)
  if (response.status === 'True') {
    // console.log(response.data)
    dispatch(events(response.data))
  }
  else {
    dispatch(events([]))
  }
  } catch (error) {
      console.log(error)
      Alert('error', error.message);
  }
  dispatch(isLoaded());
}