import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import { isLoaded, isLoading, projects } from "../actions";

//For Get Projects/Epics List
export async function getEpics(dispatch,getUser) {
    dispatch(isLoading());
    try {
      var response = await API.post("get_epics.php", {
        "corp": getUser.corp,
        "userType": getUser.role,
        "empId": getUser.empId,
        "action": "get_epics",
        "projectId": getUser.projectId
      },{},false);
      if(response.status === 'True') {
          dispatch(projects(response.data))
      }
      else{
        dispatch(projects([]))
      }
    } catch (error) {
      Alert('error',error.message);
    }
    dispatch(isLoaded());
  }