import { isLoading,isLoaded, consolidatedToDoTo, consolidatedToDoBy } from "../actions";
import API from "../../../Common/Network/API";
import Alert from "../../../Common/Alert";

export async function getConsolidatedTodoTo(dispatch,getUser) {
  dispatch(isLoading());
  try {
    var response = await API.post("get_user_stories.php", {
      "empId": getUser.empId,
      "action": "consolidated_todo_to",
    },{},false);
    if(response.status === 'True') {
        dispatch(consolidatedToDoTo(response.data))
    }
    else{
      dispatch(consolidatedToDoTo([]))
    }
  } catch (error) {
    Alert('error',error.message);
  }
  dispatch(isLoaded());
}

export async function getConsolidatedTodoBy(dispatch,getUser) {
  dispatch(isLoading());
  try {
    var response = await API.post("get_user_stories.php", {
      "empId": getUser.empId,
      "action": "consolidated_todo_by",
    },{},false);
    if(response.status === 'True') {
        dispatch(consolidatedToDoBy(response.data))
    }
    else{
      dispatch(consolidatedToDoBy([]))
    }
  } catch (error) {
    Alert("error", error.message);
  }
  dispatch(isLoaded());
}