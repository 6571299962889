// SubTasksList.js
import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { getSubStringId } from '../SubStringConvert';
import { useSelector } from 'react-redux';
import { useWebProperties } from '../webProperties';
import { reOrderSubTasks } from './Services/reOrderSubtasks';
import ListIcon from '@material-ui/icons/List';
import './reOrderSubtask.scss';
import MainTaskChatBox from '../ChatWorkflow';


const SubTasksList = ({ items, stack, onDragEnd, handleOpen, handleClose }) => {
    const getUser = useSelector(state => state.auth);
    const { SUBTASKTITLE, EDIT, DELETE, ACTION, CHANGE_STATUS, ACTION_ICON, SUBTASKS,
        ASSIGNMENT_ORDER, role_array, SCRUM_MASTER, PRODUCT_OWNER, CHAT, COMMENTS } = useWebProperties();

    const pendingSubtasks = stack.filter((list) => (list.status === 'pending' && list.activeStatus !== "-1"));
    const taskPosition_id = pendingSubtasks && pendingSubtasks.length > 0 ? pendingSubtasks[0].t_id : '';

    const accessRole = (role_array[getUser.user.role] === SCRUM_MASTER || role_array[getUser.user.role] === PRODUCT_OWNER);

    const [open, setOpen] = useState({ status: false, index: 0 })
    const [cardInfo, setCardInfo] = useState()

    const handlChatOpen = (action, us_id, subtask_id, sno) => {
        var info
        setOpen({ status: true, action: action });
        if (action === 'workflow_task') {
            info = { id: us_id, subtask_id: subtask_id, action: action, sno: sno }
        }
        setCardInfo(info)
    }
    const handleModalClose = () => {
        setOpen({ status: false, index: 0 });
        // getAllTaskMessages(dispatch, getUser.user);

    }

    const getMessagesCount = (id, subtask_id, msg, empId) => {
        const msgCount = 0
        return (
            <i>
                {
                    msgCount.length > 0 ?
                        <div className="row">
                            <img src="images/common/chat.svg" title={CHAT} alt="logo" style={{ width: '20px', height: '20px', marginLeft: "-5px" }} onClick={(event) => handlChatOpen("workflow_task", id, subtask_id, msgCount)} />
                            <span style={{ color: 'red', fontWeight: "bold" }}>{msgCount.length}</span>
                        </div>
                        :
                        <div className="row">
                            <img src="images/common/chat.svg" title={CHAT} alt="logo" style={{ width: '20px', height: '20px', marginLeft: "-5px" }} onClick={(event) => handlChatOpen("workflow_task", id, subtask_id, msgCount)} />
                        </div>
                }
            </i>
        )
    }

    return (
        <>
            <div className='tasks-header-grid'>
                <div className='tasks-header-title'></div>
                <div className='tasks-header-title'>{SUBTASKTITLE}</div>
                <div className='tasks-header-title' style={{ textAlign: 'center' }}>{ASSIGNMENT_ORDER}</div>
                <div className='tasks-header-title' style={{ textAlign: 'center' }}>{CHAT}</div>
                <div className='tasks-header-title' style={{ textAlign: 'center' }}>{COMMENTS}</div>
                <div className='tasks-header-title' style={{ textAlign: 'center' }}>{ACTION}</div>
            </div>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided) => (
                        <ul {...provided.droppableProps} ref={provided.innerRef}>
                            {stack.map((tasks, index) => (
                                <Draggable key={tasks.t_id} draggableId={tasks.t_id} index={index}>
                                    {(provided) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            <div className='tasks-table-grid'>
                                                <ListIcon />
                                                <div onClick={(event) => handleOpen("taskInfo", index)} style={{ textTransform: "capitalize", cursor: 'pointer' }}
                                                    data-toggle="tooltip" data-placement="left" title={"Description     :" + tasks.taskDesc + "\n\nAssigned By    :" + tasks.assignedBy +  "\n\nAssigned To    :"  + tasks.assignedToUser + "\n\nAssigned Date :" + tasks.assignedDate}>
                                                    {tasks.taskstatus === 'pending' ? <span>{getSubStringId(getUser.user.corp, 3)}-{tasks.us_id}-{tasks.t_id}-{tasks.taskTitle}</span> : <del><span>{getSubStringId(getUser.user.corp, 3)}-{tasks.us_id}-{tasks.t_id}-{tasks.taskTitle}</span></del>}
                                                    {tasks.taskstatus === 'pending' && tasks.activeStatus === "1" && (<span style={{ backgroundColor: '#71c016', cursor: 'pointer', color: 'white', marginLeft: 10, padding: '3px', marginTop: 5, marginBottom: 5, width: '100px', textAlign: 'center' }} data-toggle="tooltip" data-placement="bottom">In Progress</span>)}
                                                    <span>{tasks.blocked_id !== null ? <span style={{ backgroundColor: 'red', cursor: 'pointer', color: 'white', marginLeft: 10, padding: '3px', marginTop: 5, marginBottom: 5, width: '100px', textAlign: 'center' }} data-toggle="tooltip" data-placement="bottom">
                                                        {getSubStringId(getUser.user.corp, 3)}-{tasks.us_id}{'-'}{tasks.taskId}</span> : null}</span>
                                                </div>
                                                <div style={{ textAlign: 'center' }}>{tasks.priorityLevel}</div>
                                                <div style={{ textAlign: 'center' }}>
                                                    <button type="button" style={{ backgroundColor: 'transparent', border: "0", marginLeft: 10, width: '10px', padding: "0" }} >
                                                        {
                                                            getMessagesCount(tasks.us_id, tasks.t_id, tasks.allMessages, getUser.user.empId)
                                                        }
                                                    </button>
                                                </div>
                                                <div style={{ textAlign: 'center' }}>
                                                <button type="button" style={{ backgroundColor: 'transparent', border: "0", marginLeft: 10, width: '10px', padding: "0" }} 
                                                 onClick={(event) => handleOpen("taskCommentInfo", index)}>
                                                    <img
                                                            src="images/common/comments.svg"
                                                            alt="logo"
                                                            title={COMMENTS}
                                                            style={{ width: "20px", height: "25px", marginLeft: "-17px" }}
                                                        />
                                                    </button>
                                                </div>
                                                <div className="dropdown show">
                                                    {/* eslint-disable-next-line */}
                                                    <a href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-expanded="false">
                                                        <img src="images/common/actionmenu.png" title={ACTION_ICON} alt="logo" className='logo' />
                                                    </a>
                                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuLink" style={{ backgroundColor: 'transparent', border: '0' }}>
                                                        <div>{/* eslint-disable-next-line */}
                                                            {(tasks.status === 'pending') ?
                                                                <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#76C54E", color: 'white' }} onClick={(event) => handleOpen("modify", index)}>{EDIT}</button> : null}
                                                            {(tasks.status === 'pending' && taskPosition_id === tasks.t_id) ?
                                                                <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#5cb0bd", color: 'white' }} onClick={(event) => handleOpen("subtask_changeStatus", index)}>{CHANGE_STATUS}</button> : null}
                                                            {((getUser.user.empId === tasks.assignedid || accessRole) && tasks.status === 'pending' && tasks.blocked_id === null) ?
                                                                <button className="dropdown-item badge badge-pill badge-danger text-center" style={{ backgroundColor: 'red' }} onClick={(event) => handleOpen("Delete", index)}>{DELETE}</button> : null}
                                                            {(tasks.status === 'pending' && tasks.activeStatus === "1") ?
                                                                <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "rgb(173, 216, 230)", color: 'white' }} onClick={(event) => handleOpen("subtask_roadblock", index)}>Move To Roadblock</button> : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </ul>
                    )}
                </Droppable>
            </DragDropContext>
            <button type="button" className="btn btn-outline-success save-button" onClick={(event) => { reOrderSubTasks(stack, SUBTASKS, handleClose) }}>Save</button>
            {
                open.action === "workflow_task" ? <MainTaskChatBox open={open.status} handleClose={handleClose} data={cardInfo} handleModalClose={handleModalClose}
                /> : null
            }
        </>
    );
};

export default SubTasksList;