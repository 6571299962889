// services/Read/getEmployees.js
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import { isLoading, isLoaded, employees } from "../action";

export async function getEmployees(dispatch, getUser) {
  // const domain = getUser.userName.split('@')[1] // removed bcz of not getting non-domain users
  dispatch(isLoading());
  
  try {
    var responseData = await API.post("squad_chat.php", {
      "crop": getUser.corp,
      projectId: getUser.projectId,
      action: 'get_all_employees_msg_time',
      // domain: domain,
      sendBy: getUser.empId
    }, {}, false);

    if (responseData.status === 'True') {
      var employeeList = [];
      responseData.data.map((x) => {
        return (x.email !== getUser.userName ? employeeList.push(x) : null);
      });
      dispatch(employees(employeeList));
    } else {
      dispatch(employees([]));
    }
  } catch (error) {
    Alert('error', error.message);
  }

  dispatch(isLoaded());
}
