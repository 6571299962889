import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import { addCommentUserStory } from "../../Modules/Services/addCommentUserStory";

//Update the Task Status
export async function updateUserStory(dispatch, getUser,userStoryId, handleClose,message,TASKS_WARNING, ROADBLOCK_WARNING) {
  try {
    const response = await API.post(
      "manage_userstories.php",
      {
        action: "update",
        task_id: userStoryId,
        crop: getUser.corp,
        task_status: 100,
        task_status_desc: "completed",
        task_complete_status: 1,
        empId: getUser.empId,
        projectId: getUser.projectId,
      },
      {},
      false
    );
    if (response.status === "True") {
      addCommentUserStory(dispatch, getUser, userStoryId, message, "2");
    } else {
      const warning = response.type === "tasks" ? TASKS_WARNING : ROADBLOCK_WARNING;
      Alert('warning', warning)   
    }
  } catch (error) {
    Alert("error", error.message);
  }
  handleClose();
  }
  