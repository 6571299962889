// services/getAllLastSeenMessages.js

import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import { isLoading, allMessagesLast, isLoaded } from "../action"; 

export async function getAllLastSeenMessages(dispatch, getUser) {
  dispatch(isLoading());
  dispatch(allMessagesLast([]));

  try {
    var response = await API.post("squad_chat.php", {
      corp_code: getUser.corp,
      action: "get_all_messages_for_display_last_msg",
      sendBy: getUser.empId,
    }, {}, false);
    if (response.status === 'True') {
      dispatch(allMessagesLast(response.data));
    } else {
      dispatch(allMessagesLast([]));
    }
  } catch (error) {
    Alert('error', error.message);
  }
  dispatch(isLoaded());
}
