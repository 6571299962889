import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import { Client_URL } from "../../../Common/config";
import { isLoaded, isLoading } from "../actions";

//Update Employee
export async function updateEmployee(state, dispatch, getUser, handleClose, APP_NAME) {
    dispatch(isLoading());
    if ((state.empId.value !== "" && state.email.value !== "" && state.fullName.value !== "" && state.userName.value !== ""
     && state.userType.value !== "" && state.userStatus.value !== "")) {
      // && state.roleSelected.value !== "" && state.designationSelected.value !== ""
      try {
        var response = await API.post("manage_user.php", {
          empId: state.empId.value,
          employeeId: state.employeeId.value,
          fullname: state.fullName.value,
          email: state.email.value,
          username: state.userName.value,
          mobile: state.mobile.value,
          designation: state.designationSelected.value,
          user_status: state.userStatus.value,
          team: state.roleSelected.value,
          reportingManager: state.reportingManagerSelected.value,
          functionalManager: state.functionalManagerSelected.value,
          action: 'update',
          userType: state.userType.value,
          corp: getUser.corp,
          projectId:getUser.projectId,
          url: Client_URL,
          appName: APP_NAME,
        }, {}, false);
        if (response.status === "True") {
          // if (state.userStatus.value === "Active") {
          //   activateEmployee(dispatch, getUser, state.email.value, handleClose) //Activate the Employee --> GNK -02 01-APR-2021
          // } else if (state.userStatus.value === "Inactive") {
          //   deActivateEmployee(dispatch, getUser, state.email.value, handleClose) //De Activate the Employee --> GNK  -01 01-APR-2021
          // }
          Alert("success", "Updated successfully")
          handleClose();
        } else {
          Alert("warning", response.message)
          dispatch(isLoaded());
        }
      } catch (error) {
        Alert('error', error.message);
        dispatch(isLoaded());
      }
    } else {
      Alert("warning", "please fill all the details")
    }
  
  }