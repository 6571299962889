// userprofile/services/getProfile.js (READ)

import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import { isLoaded, isLoading, setUserInfo } from "../actions";

export async function getProfile(dispatch, getUser) {
    dispatch(isLoading());
    try {
        var response = await API.post("get_user.php", {
            corp: getUser.corp,
            empId: getUser.empId,
            action: "get_user_profile",
            projectId: getUser.projectId
        }, {}, false);
        if (response.status === 'True') {
            dispatch(
                setUserInfo(
                    response.data[0].empid, //Employee ID
                    response.data[0].username,
                    response.data[0].fullname,
                    response.data[0].mobile,
                    response.data[0].email,
                    response.data[0].designation,
                    response.data[0].team,
                    response.data[0].role,
                    response.data[0].reportingManager,
                    response.data[0].functionalManager,
                    response.data[0].empStatus,
                    response.data[0].shift_hours,
                    response.data[0].account_type,
                    response.data[0].license_key,
                    response.data[0].email_status,
                    response.data[0].license_validity,
                    response.free_licenses,
                    response.remaining_projects
                )
            );
        }
        else {
        }
    } catch (error) {
        Alert('error', error.message);
    }
    dispatch(isLoaded());
}