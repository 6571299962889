//accounts/services/checkEmployee
import {  isLoaded, isLoading } from "../actions";
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import { Client_URL } from "../../../Common/config";


export async function checkEmployee(state, dispatch, getUser, handleClose, APP_NAME) {
    var chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    var string_length = 8;
    var pass_code = '';
    for (var i=0; i<string_length; i++) {
        var num = Math.floor(Math.random() * chars.length);
        pass_code += chars.substring(num,num+1);
    }
    dispatch(isLoading());
    if ((state.email.value !== "" && state.fullName.value !== ""  && state.mobile.value !== "" && state.no_of_projects.value !== "" 
    && state.no_of_users.value !== "" && state.clientname.value !== "" && !(state.fullName.errorStatus || state.email.errorStatus ))) {
      try {
        // eslint-disable-next-line
        const [username, extension] = (state.email.value).split('@')
        var response = await API.post("accounts.php", {
          fullname: state.fullName.value,
          email: state.email.value,
          username: state.fullName.value,
          mobile: state.mobile.value,
          action: "new_account",
          clientname: state.clientname.value,
          userscount: state.no_of_users.value,
          projectscount: state.no_of_projects.value,
          password: state.password.value !== "" ? state.password.value : pass_code, //state.password.value,
          created_by: getUser.empId,
          extension: extension,
          url: Client_URL,
          appName: APP_NAME,
        }, {}, false);
        if (response.status === "True") {
          Alert("success", response.message)
          handleClose()
          dispatch(isLoaded());
          // addEmployee(state, dispatch, getUser, handleClose)
        } else {
          Alert("warning", response.message)
          handleClose()
          dispatch(isLoaded());
        }
      } catch (error) {
        Alert('error', error.message);
        dispatch(isLoaded());
      }
    } else {
      Alert("warning", "please fill all the details")
      dispatch(isLoaded());
    }
  
  }