import Alert from "../../Alert";
import API from "../../Network/API";
import {isLoaded, isLoading } from "../action";
import { getGroupDetails } from "./getGroupDetails";

export async function deleteGroupChat(state, dispatch, getUser, id, handleClose) {
    dispatch(isLoading());
    try {
      var response = await API.post("group_chat.php", {
        crop: getUser.corp,
        action: "delete",
        created_by: getUser.userName,
        group_Id: id,
      }, {}, false);
      if (response.status === 'True') {
        getGroupDetails(dispatch, getUser)
        // Alert("success", "Group deleted successfully!");
      }
    } catch (error) {
      Alert('error', error.message);
      dispatch(isLoaded());
    }
    handleClose()
  }