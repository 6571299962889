import Alert from "../../Alert";
import API from "../../Network/API";
import { isLoaded, isLoading } from "../action";
import { getGroupDetails } from "./getGroupDetails";

// for create group
export async function createGroupChat(state, mem, label, dispatch, getUser, handleClose) {
    dispatch(isLoading());
    if (state.title === "") {
      Alert("warning", "Please add Group Name ")
      dispatch(isLoaded());
    }
    else if (state.title !== "") {
      try {
        var response = await API.post("group_chat.php", {
          crop: getUser.corp,
          action: "create",
          projectId: getUser.projectId,
          group_name: state.title,
          created_by: getUser.userName,
          created_by_name: getUser.fullName,
          // for members names
          members: mem,
          // for members email
          label: label,
        }, {}, false);
        if (response.status === 'True') {
            getGroupDetails(dispatch, getUser)
        //   Alert("success", "Group created successfully!");
        }
        if (response.status === 'GroupExist') {
            getGroupDetails(dispatch, getUser)
          Alert("warning", "Group Already Exist!");
        }
      } catch (error) {
        Alert('error', error.message);
        dispatch(isLoaded());
      }
      handleClose()
    } else {
      Alert("warning", "Please enter required fields ")
      dispatch(isLoaded());
    }
  }