import React, { useEffect, useReducer } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import * as actions from './actions'
import { useSelector } from 'react-redux';
import { modifySubTask } from './Services/modifySubTask';
import { subtasksReducer, initialState } from './subtaskReducer';
import { useWebProperties } from '../webProperties';
import Select from 'react-select';
import { getUsers } from './Services/getProjectUsers';



const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        width: '100%',
        padding: theme.spacing(2, 4, 3),
    },
}));

//For Modify Existing SubTask
export default function ModifySubTask(props) {
    const [state, dispatch] = useReducer(subtasksReducer, initialState);
    const getUser = useSelector(state => state.auth)
    const classNames = useStyles();
    const { SUBTASK,SUBTASK_DESCRIPTION,MODIFYSUBTASK, SUBTASK_MODIFY, SELECT_SQUAD_MEMBER, ACCEPTANCE_CRITERIA, DEFINITION_OF_DONE, TARGET_DATE, ASSIGNMENT_ORDER } = useWebProperties();
    let time = props.data.estimatedHours;
    let days1 = (Number(time / 24)).toString().split(".");
    var days = days1[0];
    var hours = Number(time % 24);
    useEffect(() => {
        getUsers(dispatch, getUser.user);
        // getDependency(dispatch,getUser.user,props.data.ideaId);
        dispatch(actions.setModifySubTask(props.data.mainTaskId,props.data.subTaskId,props.data.taskTitle,props.data.taskDesc,days,hours,props.data.id, props.data.acceptanceCriteria, props.data.targetDate))
        // eslint-disable-next-line
    }, [])
    var userDetails = [];
    state.users.map((users) => {
        return (
            userDetails.push({ 'value': users.id, 'label': users.name })
        );
    })
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                closeAfterTransition
                className={classNames.modal}
                open={props.open}
                onClose={props.handleClose}
                disableBackdropClick={true}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.open}>
                    <div className={classNames.paper}>
                        <div className="user-modal-dialog d-flex justify-content-center">
                            <div className="modal-content col-lg-6 p-2" style={{ borderRadius: '10px' }}>
                                <div className="modal-header" style={{ backgroundColor: '#D2ECDF' }}>
                                    <h5 className="modal-title p-2">{MODIFYSUBTASK}</h5>
                                    <button style={{ backgroundColor: 'transparent', border: '0' }} type="button" className="d-flex align-items-right" data-dismiss="modal" onClick={props.handleModalClose}><i className="mdi mdi-close text-black"></i></button>
                                </div>
                                <div className="modal-body overflow-auto" style={{ height: 500 }}>
                                    <div class="form-group" style={{ height: 'auto' }}>
                                        <label for="recipient-name" class="col-form-label pt-0" style={{ width: '150px' }}>{SUBTASK} Title<span style={{ color: "red" }} >*</span></label>
                                        <input type="text" class="form-control" id="title" name="title" style={{ backgroundColor: 'transparent', borderBottom: '1px solid black', borderTop: '0px', borderLeft: '0px', borderRight: '0px', marginTop: '-10px' }}
                                            value={state.taskTitle.value}
                                            onChange={(event) => dispatch(actions.taskTitle(event.target.value))} />
                                        <span style={{ color: "red", fontSize: '12px' }}>{state.taskTitle.errorStatus ? state.taskTitle.errormessage : ""}</span>
                                    </div>
                                    <div class="form-group row pl-2" style={{ marginTop: "-20px" }}>
                                        <label for="acceptance" class="col-form-label pt-2" style={{ width: '250px' }}>{SUBTASK_DESCRIPTION}<span style={{ color: "red" }} >*</span></label>
                                        <textarea type="text" class="form-control" id="criteria" name="criteria" style={{ backgroundColor: 'transparent', border: '1px solid grey', height: '65px' }}
                                            value={state.taskDescription.value}
                                            onChange={(event) => dispatch(actions.taskDescription(event.target.value))} />
                                        <span style={{ color: "red", fontSize: '12px' }}>{state.taskDescription.errorStatus ? state.taskDescription.errormessage : ""}</span>
                                    </div>
                                    <div class="form-group row pl-2" style={{ marginTop: "-20px" }}>
                                        <label for="story" className="col-form-label pt-2" style={{ width: '120px' }}>{ASSIGNMENT_ORDER} :</label>
                                        <label for="story" className="col-form-label pt-2" style={{ width: '100px' }}>{props.data.priorityLevel}</label>
                                    </div>
                                    <div class="form-group row pl-2" style={{ marginTop: "-20px" }}>
                                        <label for="user" className="col-form-label pt-2" style={{ width: '150px' }}>{SELECT_SQUAD_MEMBER}</label>
                                        <Select
                                            className="form-control"
                                            placeholder={props.data.userDetails}
                                            maxMenuHeight={130}
                                            value={userDetails.value}
                                            onChange={(selectedOption) => {
                                                dispatch(actions.userSelected(selectedOption.value))
                                            }}
                                            options={userDetails}
                                        />
                                    </div>
                                    <div class="form-group row pl-2" style={{ marginTop: "-20px" }}>
                                        <label for="acceptance" class="col-form-label pt-2" style={{ width: '250px' }}>{ACCEPTANCE_CRITERIA}/{DEFINITION_OF_DONE}<span style={{ color: "red" }} >*</span></label>
                                        <textarea type="text" class="form-control" id="criteria" name="criteria" style={{ backgroundColor: 'transparent', border: '1px solid grey', height: '65px' }}
                                            value={state.acceptanceCriteria.value}
                                            onChange={(event) => dispatch(actions.acceptanceCriteria(event.target.value))} />
                                        <span style={{ color: "red", fontSize: '12px' }}>{state.acceptanceCriteria.errorStatus ? state.acceptanceCriteria.errormessage : ""}</span>
                                    </div>
                                    <div class="form-group row pl-2" style={{ marginTop: "-20px" }}>
                                    <label for="target_date" class="col-form-label pt-2" style={{ width: '250px' }}>{TARGET_DATE}<span style={{ color: "red" }} >*</span></label>
                                    <input type="date" class="form-control" id="target_date" name="target_date" style={{ backgroundColor: 'transparent', borderBottom: '1px solid black', borderTop: '0px', borderLeft: '0px', borderRight: '0px', marginTop: '-10px' }}
                                        value={state.targetDate.value}
                                        onChange={(event) => dispatch(actions.targetDate(event.target.value))} />
                                    </div>
                                </div>
                                {/* <div className="modal-body">
                                    <div>
                                        <form >
                                            <div className="form-group row p-2">
                                                <label className="col-form-label pt-4" style={{width:'80px'}}>{SUBTASK} Title</label>
                                                <input type="text" className="form-control col-10 ml-2" id="title" name="title" style={{ backgroundColor: 'transparent', borderBottom: '1px solid black', borderTop: '0px', borderLeft: '0px', borderRight: '0px' }}
                                                    value={state.taskTitle.value}
                                                    onChange={(event) => dispatch(actions.taskTitle(event.target.value))} />
                                                <label className="col-form-label pt-4" style={{width:'80px'}}>{SUBTASK_DESCRIPTION}</label>
                                                <input type="text" className="form-control col-10 ml-2" id="ddescription" name="description" style={{ backgroundColor: 'transparent', borderBottom: '1px solid black', borderTop: '0px', borderLeft: '0px', borderRight: '0px' }}
                                                    value={state.taskDescription.value}
                                                    onChange={(event) => dispatch(actions.taskDescription(event.target.value))} />
                                             
                                                </div>
                                                
                                              
                                        </form>
                                    </div>
                                </div> */}
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-outline-success" style={{ borderRadius: '20px' }} onClick={(event) => { modifySubTask(state, dispatch, getUser.user, props.data.mainTaskId, props.handleClose,SUBTASK_MODIFY, SUBTASK, SUBTASK_DESCRIPTION, ACCEPTANCE_CRITERIA) }}>Save</button>
                                    {/* onClick={(event) => { addProject(state, dispatch, getUser.user, props.handleClose) }} */}
                                </div>

                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div >
    );
}