import API from "../../../Common/Network/API";
import Alert from "../../../Common/Alert";
import { addCommentUserStory } from "./addCommentUserStory";


export async function getActiveUserStory(getUser,story_id,moduleId,handleClose,handleOpen,message) {
  try {
    var response = await API.post(
      "manage_userstories.php",
      {
        crop: getUser.corp,
        story_id: story_id,
        empId: getUser.empId,
        action: "activate_user_story",
      },
      {},
      false
    );
    if (response.status === "true") {
      addCommentUserStory(getUser, story_id, message, "1");
      handleClose();

    } else if (response.status === "true1") {
      handleClose();
      const data = { inprogressStoryId: response.data, todoStoryId: story_id, currentSprint: moduleId, assignedTo: response.assignedTo, projectName: response.project_name, story_title: response.story_title, inprogress_story_title: response.inprogress_story_title, us_id: response.us_id, todo_us_id : response.todo_us_id }
      handleOpen("changeUserstoryStatus", '0' ,  data); //For change the UserStory Status

    } else {
      Alert("warning", response.message);

    }
  } catch (error) {
    console.log(error);
  }  
}