import Alert from "../../../Common/Alert";
import { isLoaded, isLoading } from "../actions";
import { getDoneUserStoriesToKanban } from "./getDoneUserStoriesToKanban";
import { getInvolvedEmployees } from "./getInvolvedEmployees";
import { getRoadBlockUserStoriesToKanban } from "./getRoadBlockUserStoriesToKanban";
import { getToDoUserStories } from "./getToDoUserStories";

export async function getCurrentSprintUserStoriesToKanban(dispatch, getUser, sprintId) {
    dispatch(isLoading());
    try {
      getToDoUserStories(dispatch, getUser, sprintId);
      getRoadBlockUserStoriesToKanban(dispatch, getUser, sprintId);
      getDoneUserStoriesToKanban(dispatch, getUser, sprintId);
      getInvolvedEmployees(dispatch, getUser, sprintId);
    } catch (error) {
      Alert("error", error.message);
    }
    dispatch(isLoaded());
  }
  