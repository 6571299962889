import React, { useEffect, useReducer, useState } from 'react';
import $ from 'jquery';
import { useSelector } from 'react-redux';
import { getSubTasks } from './Services/getSubtask';
import RootLoader from '../../Common/Loader/RootLoader';
// import Header from '../TopNav';
import SideNavigation from '../SideNav';
import { getToken, setToken } from '../LocalStorage';
import { subtasksReducer, initialState } from './subtaskReducer';
import ChatBox from '../Chat';
import ModifySubTask from './modifySubtask';
import AddSubTask from './addSubTask';
import SubTaskVerify from '../TaskVerify/subtaskVerify';
import SubTaskDelete from './deleteSubTask';
import ChangeStatus from '../../UserModule/Backlogs/changeStatus';
import MainTaskInfo from '../TasksModals/mainTaskInfo';
import TopNavWithOutProject from '../../UserModule/Utility/TopNav';
import { useWebProperties } from '../webProperties';
// import { getSubStringId } from '../SubStringConvert';
// import {
//     SortableContainer,
//     SortableElement } from 'react-sortable-hoc';
import { manageSubTasks } from './actions';
import SubTasksList from './ReOrderSubTask';
import RoadBlock from '../RoadBlock';
import TaskInfo from '../../UserModule/UserDashboard/taskInfo';

//   function findById(array, t_id) {
//     const elementId = t_id;
//     function checkId(element) {
//       return element.id === elementId;
//     }
//     return array.findIndex(checkId);
//   }


// function rankNewPosition(array, t_id, newIndex) {
//     let newOrder = array;
//     const rankIndex = findById(newOrder, t_id);
//     newOrder.splice(newIndex, 0, newOrder.splice(rankIndex, 1)[0]);

//     let i = 1;
//     newOrder = newOrder.map((rank) => {
//       const updatedRankList = rank;
//       updatedRankList.position = i;
//       i += 1;
//       return updatedRankList;
//     });
//     return newOrder;
//   }

export default function ViewSubTasks(props) {
    var data = {
        id: props?.location?.state?.id || getToken('task_id'),
        title: props?.location?.state?.title || getToken('task_title'),
        moduleId: props?.location?.state?.moduleId || getToken('moduleId'),
        ideaId: props?.location?.state?.ideaId || getToken('ideaId'),
        assignedTo: props?.location?.state?.subTasksAssignedTo || getToken('assignedTo'),
        targetDate: props?.location?.state?.targetDate || getToken('targetDate'),
        us_id: props?.location?.state?.us_id || getToken('us_id'),
        is_workflow: props?.location?.state?.is_workflow || getToken('is_workflow'),
        template_story_id: props?.location?.state?.template_story_id || getToken('template_story_id'),
        completeStatus: props?.location?.state?.completeStatus || getToken('completeStatus'),
    }
    // console.log(props,data.assignedTo, props.location.state.subTasksAssignedTo)
    setToken('task_id', data.id)
    setToken('task_title', data.title)
    setToken('moduleId', data.moduleId)
    setToken('ideaId', data.ideaId)
    setToken('assignedTo', data.assignedTo)
    setToken('targetDate', data.targetDate)
    setToken('us_id', data.us_id)
    setToken('is_workflow', data.is_workflow)
    setToken('template_story_id', data.template_story_id)
    setToken('completeStatus', data.completeStatus)

    const getUser = useSelector(state => state.auth)
    const [state, dispatch] = useReducer(subtasksReducer, initialState)
    const [open, setOpen] = useState({ status: false, action: "", index: 0 })
    const [taskInfo, setTaskInfo] = useState();
    const { SUBTASKS, MAINTASKNAME, NEWSUBTASK, SUBTASK } = useWebProperties();
    useEffect(() => {
        if (props.location.data !== undefined && props.location.data !== "") {
            getSubTasks(dispatch, getUser.user, getToken('task_id'));
            // getAllTaskMessages(dispatch, getUser.user);
        }
        getSubTasks(dispatch, getUser.user, getToken('task_id'));
        // getAllTaskMessages(dispatch, getUser.user);
        // eslint-disable-next-line
    }, [props.location.data])
    useEffect(() => {
        if (state.manageSubTasks.length > 0) {
            $(document).ready(function () {
                window.$('#example').DataTable({
                    destroy: true,
                    retrieve: true,
                    fixedHeader: true,
                })
            })
        }
    }, [state.manageSubTasks])
    const handleOpen = (action, index, sno) => {
        setOpen({ status: true, action: action, index: index });
        var info;
        if (action === "add") {
            info = {
                mainTaskId: getToken('task_id'), moduleId: getToken('moduleId'), ideaId: getToken('ideaId'), assignedTo: data.assignedTo, targetDate: data.targetDate,
                us_id: getToken('us_id'),
                title: getToken('task_title'),
                is_workflow: getToken('is_workflow'),
                template_story_id: getToken('template_story_id'),
                completeStatus: getToken('completeStatus'),
            }
        } else if (action === "modify") {
            info = {
                mainTaskId: state.manageSubTasks[index].mainTaskId,
                subTaskId: state.manageSubTasks[index].subTaskId,
                taskTitle: state.manageSubTasks[index].taskTitle,
                taskDesc: state.manageSubTasks[index].taskDesc,
                estimatedHours: state.manageSubTasks[index].estimatedHours,
                us_id: state.manageSubTasks[index].us_id,
                t_id: state.manageSubTasks[index].t_id,
                action: action,
                ideaId: getToken('ideaId'),
                acceptanceCriteria: state.manageSubTasks[index].acceptanceCriteria,
                priorityLevel: state.manageSubTasks[index].priorityLevel,
                targetDate: state.manageSubTasks[index].targetDate,
                userDetails: state.manageSubTasks[index].assignedToUser,
                id: state.manageSubTasks[index].assignedToid,
            }
        } else if (action === "Delete") {
            info = {
                mainTaskId: state.manageSubTasks[index].mainTaskId,
                subTaskId: state.manageSubTasks[index].subTaskId,
                taskTitle: state.manageSubTasks[index].taskTitle,
                taskDesc: state.manageSubTasks[index].taskDesc,
                estimatedHours: state.manageSubTasks[index].estimatedHours,
                us_id: state.manageSubTasks[index].us_id,
                t_id: state.manageSubTasks[index].t_id,
                action: action, ideaId: getToken('ideaId'),
                priority: state.manageSubTasks[index].priorityLevel,
            }
        } else if (action === 'subtask_roadblock') {
            info = {
                mainTaskId: state.manageSubTasks[index].mainTaskId,
                subTaskId: state.manageSubTasks[index].subTaskId,
                action: action,
                priority: state.manageSubTasks[index].priorityLevel,
                message: `${SUBTASK} is blocked`
            }
        } else if (action === "Verify") {
            info = {
                mainTaskId: state.manageSubTasks[index].mainTaskId,
                subTaskId: state.manageSubTasks[index].subTaskId,
                taskTitle: state.manageSubTasks[index].taskTitle,
                action: action
            }

        }
        else if (action === "taskInfo") {
            info = {
                action: action,
                taskTitle: state.manageSubTasks[index].tasktitle,
                subTaskDesc: state.manageSubTasks[index].taskDesc,
                assignedBy: state.manageSubTasks[index].assignedBy,
                assignedTo: state.manageSubTasks[index].assignedTo,
                targetDate: state.manageSubTasks[index].targetDate,
                task: state.manageSubTasks[index].taskTitle,
                us_id: state.manageSubTasks[index].us_id,
                t_id: state.manageSubTasks[index].t_id,
                acceptanceCriteria: state.manageSubTasks[index].acceptanceCriteria,
                priorityLevel: state.manageSubTasks[index].priorityLevel,
                userDetails: state.manageSubTasks[index].assignedToUser,
                id: state.manageSubTasks[index].assignedToid,
            }
        }
        else if (action === "subtask_changeStatus" || action === "taskCommentInfo") {
            info = {
                us_id: state.manageSubTasks[index].us_id,
                id: state.manageSubTasks[index].subTaskId,
                t_id: state.manageSubTasks[index].t_id,
                title: state.manageSubTasks[index].taskTitle, action: action, taskId: state.manageSubTasks[index].mainTaskId, subTaskId: state.manageSubTasks[index].subTaskId, activeStatus: state.manageSubTasks[index].activeStatus, completeStatus: state.manageSubTasks[index].status
            }
        }
        else {
            info = {
                action: action,
                id: state.manageSubTasks[index].subTaskId,
                us_id: state.manageSubTasks[index].us_id,
                t_id: state.manageSubTasks[index].t_id,
                sno: sno
            }
        }
        setTaskInfo(info)
    };
    const handleClose = () => {
        setOpen({ status: false, index: 0 });
        getSubTasks(dispatch, getUser.user, getToken('task_id'));
    };
    const handleModalClose = () => {
        setOpen({ status: false, index: 0 });
        if (open.action !== "taskInfo" && open.action !== "taskCommentInfo") {getSubTasks(dispatch, getUser.user, getToken('task_id'));}
    }

    // const SortableItem = SortableElement(({value: {t_id, us_id, taskTitle}}) => (
    //     <div tabIndex={0}>{getSubStringId(getUser.user.corp, 3)}-{us_id}-{t_id}-{taskTitle}</div>
    //   ));

    // const SortableList = SortableContainer(({items}) => {
    //     console.log(items);
    //     return (
    //       <ul>
    //         {items.map((value, index) => (
    //           <SortableItem key={`item-${value.t_id}`} index={index} value={value} />
    //         ))}
    //       </ul>
    //     );
    //   });

    // const changeListingRanksOrder = ({ oldIndex, newIndex }) => {
    //     const updatedRankListings = rankNewPosition(state.manageSubTasks, state.manageSubTasks[oldIndex].t_id, newIndex);
    //     console.log(updatedRankListings);
    //     dispatch(manageSubTasks(updatedRankListings))
    //   };

    const onDragEnd = (result) => {
        if (!result.destination) return;

        const reorderedItems = Array.from(state.manageSubTasks);
        const [removed] = reorderedItems.splice(result.source.index, 1);
        reorderedItems.splice(result.destination.index, 0, removed);
        dispatch(manageSubTasks(reorderedItems))
    };

    return (
        <div className="container-scroller">
            <TopNavWithOutProject />
            <div className="container-fluid page-body-wrapper">
                <SideNavigation />
                <div className="main-panel">
                    <div className="mt-2">
                        <div className="col-lg-12 grid-margin stretch-card">
                            <div className="card">
                                <div className="card-body">
                                    <div>
                                        <div className="row justify-content-between">
                                            <h4 className="card-title">{SUBTASKS}</h4>
                                            <h4 className="card-title text-success">{MAINTASKNAME}: {getToken('task_title')}</h4>
                                            {getToken('completeStatus') === 'pending' && <div className="d-flex justify-content-end mb-2">
                                                <button style={{ backgroundColor: 'transparent', border: '0' }} type="button" onClick={() => handleOpen("add")}> <img src="images/common/add.png" title={NEWSUBTASK} alt="logo" style={{ width: '20px', height: '20px' }} /><span className="m-1">{NEWSUBTASK}</span></button>
                                                {
                                                    open.action === "add" ? <AddSubTask open={open.status} handleClose={handleClose} data={taskInfo} handleModalClose={handleModalClose}
                                                    /> : null
                                                }
                                            </div>}

                                        </div>
                                    </div>
                                    {/* <SortableList items={state.manageSubTasks} onSortEnd={changeListingRanksOrder} /> */}
                                    {/* {newList.map((value, index) => (
                                        <SortableItem key={`item-${value.title}`} index={index} value={value} />
                                        ))} */}
                                    {/* </SortableList> */}
                                    {state.isLoading
                                        ? <RootLoader />
                                        : <SubTasksList stack={state.manageSubTasks} items={state.subTasks} onDragEnd={onDragEnd} handleOpen={handleOpen} handleClose={handleClose} />
                                    }
                                    {/* {state.isLoading ? <RootLoader /> :
                                        <div className="table-responsive">

                                            <MDBTable>
                                                <table
                                                    search="true"
                                                    id="example" className="table table-striped table-bordered"
                                                    data-pagination="true"
                                                >
                                                    <thead style={{ backgroundColor: '#F4FAF7' }}>
                                                        <tr>
                                                            <th>{SUBTASKTITLE}12</th>
                                                            <th>{ACTION}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            state.manageSubTasks.length > 0 ? state.manageSubTasks.map((tasks, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td onClick={(event) => handleOpen("taskInfo", index)} style={{ textTransform: "capitalize", cursor: 'pointer' }} data-toggle="tooltip" data-placement="left" title={"Description     :" + tasks.taskDesc + "\n\nAssigned By    :" + tasks.assignedBy + "\n\nAssigned Date :" + tasks.assignedDate}>{tasks.taskstatus === 'pending' ? <b>{getSubStringId(getUser.user.corp, 3)}-{tasks.us_id}-{tasks.t_id}-{tasks.taskTitle}</b> : <del> <b>{getSubStringId(getUser.user.corp, 3)}-{tasks.us_id}-{tasks.t_id}-{tasks.taskTitle}</b></del>}</td>
                                                                        <td style={{ textAlign: 'center', width: '10px' }}>
                                                                            <div className="dropdown show">
                                                                                <a href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-expanded="false">
                                                                                    <img src="images/common/actionmenu.png" title={ACTION_ICON} alt="logo" style={{ width: '20px', height: '20px', borderRadius: '0' }} />
                                                                                </a>

                                                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuLink" style={{ backgroundColor: 'transparent', border: '0' }}>

                                                                                    <div>
                                                                                        {(tasks.status === 'pending') ? <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#76C54E", color: 'white' }} onClick={(event) => handleOpen("modify", index)}>{EDIT}</button> : null}
                                                                                        {(getUser.user.empId === tasks.assignedid) ? <button className="dropdown-item badge badge-pill badge-danger text-center" style={{ backgroundColor: 'red' }} onClick={(event) => handleOpen("Delete", index)}>{DELETE}</button> : null}
                                                                                        {(tasks.status === 'pending') ? <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#5cb0bd", color: 'white' }} onClick={(event) => handleOpen("subtask_changeStatus", index)}>{CHANGE_STATUS}</button> : null}

                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }) : null}
                                                    </tbody>
                                                </table>
                                            </MDBTable>
                                        </div>
                                    } */}
                                    {
                                        open.action === "modify" ? <ModifySubTask open={open.status} handleClose={handleClose} data={taskInfo} handleModalClose={handleModalClose}
                                        /> : null
                                    }
                                    {
                                        open.action === "Delete" ? <SubTaskDelete open={open.status} handleClose={handleClose} data={taskInfo} handleModalClose={handleModalClose}
                                        /> : null
                                    }
                                    {
                                        open.action === "subtask" ? <ChatBox open={open.status} handleClose={handleClose} data={taskInfo} handleModalClose={handleModalClose}
                                        /> : null
                                    }
                                    {
                                        open.action === "Verify" ? <SubTaskVerify open={open.status} handleClose={handleClose} data={taskInfo} handleModalClose={handleModalClose}
                                        /> : null
                                    }
                                    {
                                        open.action === "subtask_changeStatus" ? <ChangeStatus open={open.status} handleClose={handleClose} data={taskInfo} handleModalClose={handleModalClose}
                                        /> : null
                                    }
                                    {
                                        open.action === "taskInfo" ? <MainTaskInfo open={open.status} handleClose={handleClose} data={taskInfo} handleModalClose={handleModalClose}
                                        /> : null
                                    }
                                    {open.action === "subtask_roadblock" ? (
                                        <RoadBlock
                                            open={open.status}
                                            handleClose={handleClose}
                                            data={taskInfo}
                                            handleModalClose={handleModalClose}
                                        />
                                    ) : null}
                                    {open.status && open.action === "taskCommentInfo" ? (
                                        <TaskInfo
                                            open={open.status}
                                            handleClose={handleModalClose}
                                            data={taskInfo}
                                            handleModalClose={handleModalClose}
                                        />
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}