import React, { useEffect, useReducer, useState } from 'react';
import $ from 'jquery';
import { useSelector } from 'react-redux';
import RootLoader from '../../Common/Loader/RootLoader';
import SideNavigation from '../SideNav';
import { getToken, setToken } from '../LocalStorage';
import TopNavWithOutProject from '../../UserModule/Utility/TopNav';
import { useWebProperties } from '../webProperties';
import { initialState, tasksReducer } from './tasksReducer';
import { getWorkflowTemplates } from './Services/getWorkflowTemplates';
import { workflowTemplates } from './actions';
import WorkflowList from './ReOrderWorkFlowTemplates';

export default function ViewWorkFlowTemplates(props) {
    var data = {
        story_id: props?.location?.state?.story_id || getToken('story_id'),
        title: props?.location?.state?.title || getToken('title'),
        us_id: props?.location?.state?.us_id || getToken('us_id'),
    }
    setToken('story_id', data.story_id)
    setToken('title', data.title)
    setToken('us_id', data.us_id)

    const getUser = useSelector(state => state.auth);
    const [state, dispatch] = useReducer(tasksReducer, initialState)
    const [open, setOpen] = useState({ status: false, action: "", index: 0 })
    const [taskInfo, setTaskInfo] = useState();
    const { TEMPLATE, TEMPLATE_NAME } = useWebProperties();
    useEffect(() => {
        if (props.location.data !== undefined && props.location.data !== "") {
            getWorkflowTemplates(dispatch, getUser.user,getToken('story_id'));
        }
        getWorkflowTemplates(dispatch, getUser.user, getToken('story_id'));
        // eslint-disable-next-line
    }, [props.location.data])
    useEffect(() => {
        if (state.workflowTemplates.length > 0) {
            $(document).ready(function () {
                window.$('#example').DataTable({
                    destroy: true,
                    retrieve: true,
                    fixedHeader: true,
                })
            })
        }
    }, [state.workflowTemplates])
    const handleOpen = (action, index, sno) => {
        setOpen({ status: true, action: action, index: index });
        var info;
        if (action === "add") {
            info = {
                mainTaskId: getToken('story_id'),
                title: getToken('task_title'),
            }
        // } else if (action === "modify") {
        //     info = {
        //         mainTaskId: state.workflowTemplates[index].mainTaskId,
        //         subTaskId: state.workflowTemplates[index].subTaskId,
        //         taskTitle: state.workflowTemplates[index].taskTitle,
        //         taskDesc: state.workflowTemplates[index].taskDesc,
        //         estimatedHours: state.workflowTemplates[index].estimatedHours,
        //         us_id: state.workflowTemplates[index].us_id,
        //         t_id: state.workflowTemplates[index].t_id,
        //         action: action,
        //         ideaId: getToken('ideaId'),
        //         acceptanceCriteria: state.workflowTemplates[index].acceptanceCriteria,
        //         priorityLevel: state.workflowTemplates[index].priorityLevel,
        //         targetDate: state.workflowTemplates[index].targetDate,
        //         userDetails: state.workflowTemplates[index].assignedToUser,
        //         id: state.workflowTemplates[index].assignedToid,
        //     }
        // } else if (action === "Delete") {
        //     info = {
        //         mainTaskId: state.workflowTemplates[index].mainTaskId,
        //         subTaskId: state.workflowTemplates[index].subTaskId,
        //         taskTitle: state.workflowTemplates[index].taskTitle,
        //         taskDesc: state.workflowTemplates[index].taskDesc,
        //         estimatedHours: state.workflowTemplates[index].estimatedHours,
        //         us_id: state.workflowTemplates[index].us_id,
        //         t_id: state.workflowTemplates[index].t_id,
        //         action: action, ideaId: getToken('ideaId'),
        //         priority: state.workflowTemplates[index].priorityLevel,
        //     }
        // } else {
        //     info = {
        //         action: action,
        //         id: state.workflowTemplates[index].subTaskId,
        //         us_id: state.workflowTemplates[index].us_id,
        //         t_id: state.workflowTemplates[index].t_id,
        //         sno: sno
        //     }
        // }
        }
        setTaskInfo(info)
        // console.log(taskInfo, open);
    };
    const handleClose = () => {
        setOpen({ status: false, index: 0 });
        getWorkflowTemplates(dispatch, getUser.user, getToken('story_id'));
    };
    // const handleModalClose = () => {
    //     setOpen({ status: false, index: 0 });
    //     if (open.action !== "taskInfo") getWorkflowTemplates(dispatch, getUser.user, getToken('story_id'));
    // }

    const onDragEnd = (result) => {
        if (!result.destination) return;
        const reorderedItems = Array.from(state.workflowTemplates);
        const [removed] = reorderedItems.splice(result.source.index, 1);
        reorderedItems.splice(result.destination.index, 0, removed);
        dispatch(workflowTemplates(reorderedItems))
    };

    return (
        <div className="container-scroller">
            <TopNavWithOutProject />
            <div className="container-fluid page-body-wrapper">
                <SideNavigation />
                <div className="main-panel">
                    <div className="mt-2">
                        <div className="col-lg-12 grid-margin stretch-card">
                            <div className="card">
                                <div className="card-body">
                                    <div>
                                        <div className="row justify-content-between ml-2 mr-2">
                                            <h4 className="card-title">{TEMPLATE}</h4>
                                            <h4 className="card-title text-success">{TEMPLATE_NAME}: {getToken('title')}</h4>
                                        </div>
                                    </div>
                                    {state.isLoading
                                        ? <RootLoader />
                                        : <WorkflowList stack={state.workflowTemplates} taskInfo={taskInfo} open={open} items={state.workflowTemplates} onDragEnd={onDragEnd} handleOpen={handleOpen} handleClose={handleClose}/>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}