import React, { useEffect, useReducer } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import * as actions from './actions'
import { useSelector } from 'react-redux';
import { getUsers } from './Services/getUsers';
import { tasksReducer, initialState } from './tasksReducer';
import Select from 'react-select';
import RootLoader from '../Loader/RootLoader';
import { Link } from 'react-router-dom';
import { useWebProperties } from '../webProperties';
import { useState } from 'react';
// import { createMainTask } from './Services/createMainTask'; //Add task is hided in 4.2.4 release
import { getProjects } from './Services/getProjects';
import { createMainTaskTemplate } from './Services/createMainTaskTemplate';
import { createTemplateOnly } from './Services/createTemplateOnly';
import { getGroup } from './Services/getGroup';
import { removeDuplicatesFromString } from '../commonUsage';
// import EdiText from 'react-editext'


const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        width: '100%',
        padding: theme.spacing(2, 4, 3),
    },
}));

//For Add New Project
export default function AddMainTask(props) {
    const [state, dispatch] = useReducer(tasksReducer, initialState);
    const getUser = useSelector(state => state.auth)
    const [manualValue, setManualValue] = useState(null);
    const classNames = useStyles();
    const { NEWMAINTASK, MAINTASKNAME, PROJECT, MAINTASK_DESCRIPTION, STORY_POINTS,
        SELECT_SQUAD_MEMBER, ACCEPTANCE_CRITERIA, DEFINITION_OF_DONE, PRIORITY_LEVEL,
        MAINTASK_ADD, WORKFLOW } = useWebProperties();
    useEffect(() => {
        getUsers(dispatch, getUser.user);
        getProjects(dispatch, getUser.user);
        getGroup(dispatch, getUser.user)
        setManualValue(null)
        dispatch(actions.storySelected('2'))
        dispatch(actions.prioritySelected('2'))
        // eslint-disable-next-line
    }, [])





    const [selectedStoryValue, setSelectedStoryValue] = useState({ value: '2', label: '2 hrs' });
    const [selectedPriorityValue, setSelectedPriorityValue] = useState({ value: '2', label: '2' });
    const [selectedUserValue, setSelectedUserValue] = useState([]);
    const [selectedEpicValue, setSelectedEpicValue] = useState([])

    useEffect(() => {
        if (state.users.length > 0) {
            let userArray = state.users.map((user) => {
                return (
                    user.workingStatus === "Active" ?
                        { 'value': user.id, 'label': user.name, 'device_id': user.device_id, 'from': 'user', email: user.userName } : null
                );
            });

            // Assuming `getUser` and `dispatch` are available from props or context
            const currentUserEmail = getUser.user.userName;
            let array = userArray.filter(item => item && item.email === currentUserEmail);

            if (array.length > 0) {
                setSelectedUserValue(array[0]);
                dispatch(actions.userSelected(array[0]));
            }
        }
    }, [state.users, getUser.user.userName, dispatch]);


    useEffect(() => {
        if (state.projects.length > 0) {
            let epicArray = state.projects.map((epic) => {
                return {
                    'value': epic.idea_id,
                    'label': epic.idea_title
                };
            });

            if (epicArray.length > 0) {
                setSelectedEpicValue(epicArray[0]);
                dispatch(actions.epicSelected(epicArray[0]?.value));
            }
        }
    }, [state.projects, dispatch]);

    var userDetails = [];
    var storyPoints = [];
    var priorityLevels = [];
    var epicsList = [];
    var storyPointsArray = [{ id: '1', story: '1', hrs: '1hr' }, { id: '2', story: '2', hrs: '2hrs' }, { id: '3', story: '3', hrs: '3hrs' }, { id: '5', story: '5', hrs: '5hrs' }, { id: '8', story: '8', hrs: '8hrs' }, { id: '13', story: '13', hrs: '13hrs' }, { id: "Other", story: "Other", hrs: 'hrs' }]
    var priorityLevelsArray = [{ id: '1', priority: '1' }, { id: '2', priority: '2' }, { id: '3', priority: '3' }, { id: '4', priority: '4' }]


    storyPointsArray.map((storyPointsArray) => {
        return (
            storyPoints.push({ 'value': storyPointsArray.id, 'label': `${storyPointsArray.story}-${storyPointsArray.hrs}` })
        );
    })
    priorityLevelsArray.map((priorityLevelsArray) => {
        return (
            priorityLevels.push({ 'value': priorityLevelsArray.id, 'label': priorityLevelsArray.priority })
        );
    })
    state.users.map((users) => {
        return (
            users.workingStatus === "Active" ?
                userDetails.push({ 'value': users.id, 'label': users.name, 'device_id': users.device_id, 'from': 'user' }) : null
        );
    })
    state.projects.map((epics) => {
        return (
            epicsList.push({
                'value': epics.idea_id, 'label': epics.idea_title
            })
        );
    })
    state.groups.map((group) => {
        return (
            userDetails.push({ 'value': group.id, 'label': `${group.group_name}-[${removeDuplicatesFromString(group.members_name)}]`, 'members_email': group.members_email, 'from': 'group' })
        );
    })

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                closeAfterTransition
                className={classNames.modal}
                open={props.open}
                onClose={props.handleClose}
                disableBackdropClick={true}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.open}>
                    {state.isLoading ? <RootLoader /> : <div className={classNames.paper}>
                        <div className="user-modal-dialog d-flex justify-content-center md">
                            <div className="modal-content col-lg-6 p-2" style={{ borderRadius: '10px' }}>
                                <div class="task-modal-header" style={{ backgroundColor: '#D2ECDF' }}>
                                    <h5 class="modal-title p-2">{NEWMAINTASK}</h5>
                                    <div style={{ display: 'flex' }}>
                                        {state.isLoading ? <RootLoader /> :
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Link to={{ pathname: '/templateView' }} ><button disabled={selectedUserValue?.from === 'group'} type="button" class="btn btn-outline-primary" style={{ borderRadius: '20px' }} >
                                                    Use Template
                                                </button></Link>&nbsp;&nbsp;
                                                {/* this add related to create Add & Save as a {WORKFLOW} Template */}
                                                <button type="button" class="btn btn-outline-success" style={{ borderRadius: '20px' }}
                                                    onClick={(event) => { createMainTaskTemplate(state, dispatch, getUser.user, props.handleClose, MAINTASK_ADD, 1)}}>Add</button>&nbsp;&nbsp;
                                                <div className="dropdown show" style={{ cursor: 'pointer', marginRight: 15, padding: '5px' }}>
                                                    {/* eslint-disable-next-line */}
                                                    <a href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-expanded="false">
                                                        <img src="images/common/actionmenu.png" title="More" alt="logo" style={{ width: '15px', height: '15px', borderRadius: '0' }} />
                                                    </a>
                                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuLink" style={{ backgroundColor: 'transparent', border: '0', boxShadow: 'none' }}>
                                                        <button disabled={selectedUserValue?.from === 'group'} type="button" class="btn btn-success" style={{ borderRadius: '20px' }}
                                                            onClick={(event) => { createMainTaskTemplate(state, dispatch, getUser.user, props.handleClose, MAINTASK_ADD) }}>Add & Save as a Template</button>
                                                        <button disabled={selectedUserValue?.from === 'group'} type="button" class="btn btn-primary" style={{ borderRadius: '20px' }}
                                                            onClick={(event) => { createTemplateOnly(state, dispatch, getUser.user, props.handleClose) }}>Save as a Template</button>
                                                        {/* Add task is hided in 4.2.4 release
                                                        <button disabled={selectedUserValue?.from === 'group'} type="button" class="btn btn-success" style={{ borderRadius: '20px' }}
                                                            onClick={(event) => { createMainTask(state, dispatch, getUser.user, props.handleClose, MAINTASK_ADD) }}>Add</button> */}
                                                        <button disabled={selectedUserValue?.from === 'group'} type="button" class="btn btn-primary" style={{ borderRadius: '20px' }}
                                                            onClick={(event) => { createTemplateOnly(state, dispatch, getUser.user, props.handleClose, 1) }}>Save as a {WORKFLOW} Template</button>

                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <button style={{ backgroundColor: 'transparent', border: '0' }} type="button" className="d-flex align-items-right p-2" data-dismiss="modal" onClick={props.handleModalClose}><i class="mdi mdi-close text-black"></i></button>
                                    </div>
                                </div>
                                <div className="modal-body overflow-auto" style={{ height: 500 }}>

                                    <div class="form-group" style={{ height: 'auto' }}>
                                        <label for="recipient-name" class="col-form-label pt-0" style={{ width: '150px' }}>{MAINTASKNAME}<span style={{ color: "red" }} >*</span></label>
                                        <input type="text" class="form-control" id="title" name="title" style={{ backgroundColor: 'transparent', borderBottom: '1px solid black', borderTop: '0px', borderLeft: '0px', borderRight: '0px', marginTop: '-10px' }}
                                            value={state.taskTitle.value}
                                            onChange={(event) => dispatch(actions.taskTitle(event.target.value))} />
                                        <span style={{ color: "red", fontSize: '12px' }}>{state.taskTitle.errorStatus ? state.taskTitle.errormessage : ""}</span>
                                    </div>

                                    <div class="form-group row pl-2" style={{ marginTop: "-20px" }}>
                                        <label for="recipient-name" class="col-form-label pt-2" style={{ width: '100px' }}>{MAINTASK_DESCRIPTION}<span style={{ color: "red" }} >*</span></label>

                                        <textarea class="form-control" id="description" name="description" style={{ backgroundColor: 'transparent', borderBottom: '1px solid black', borderTop: '1px solid blackpx', borderLeft: '1px solid black', borderRight: '1px solid black', height: '65px', }}
                                            value={state.taskDescription.value}
                                            placeholder="As a [person],____ I [want to],____ [so that]____"
                                            onChange={(event) => dispatch(actions.taskDescription(event.target.value))} />
                                        <span style={{ color: "gray", fontSize: '12px' }}>{state.taskDescription.errorStatus ? state.taskDescription.errormessage : ""}</span>
                                    </div>
                                    <div class="form-group row pl-2" style={{ marginTop: "-20px" }}>
                                        <label for="acceptance" class="col-form-label pt-2" style={{ width: '250px' }}>{ACCEPTANCE_CRITERIA}/{DEFINITION_OF_DONE}<span style={{ color: "red" }} >*</span></label>
                                        <textarea type="text" class="form-control" id="criteria" name="criteria" style={{ backgroundColor: 'transparent', border: '1px solid grey', height: '65px' }}
                                            value={state.acceptanceCriteria.value}
                                            onChange={(event) => dispatch(actions.acceptanceCriteria(event.target.value))} />
                                        <span style={{ color: "red", fontSize: '12px' }}>{state.acceptanceCriteria.errorStatus ? state.acceptanceCriteria.errormessage : ""}</span>




                                    </div>
                                    <div class="form-group row pl-2" style={{ marginTop: "-20px",display: 'grid', gap: '0.5rem', gridTemplateColumns: '1fr 1fr' }}>
                                        <div>
                                        {state.projects.length > 0 && <label for="epic" className="col-form-label pt-2" style={{ width: '100px' }}>{PROJECT} Name</label>}

                                        {state.projects.length > 0 && <Select
                                            className="form-control "
                                            placeholder={`Select ${PROJECT}`}
                                            value={selectedEpicValue}
                                            maxMenuHeight={130}
                                            onChange={(selectedOption) => {
                                                setSelectedEpicValue(selectedOption)
                                                dispatch(actions.epicSelected(selectedOption.value))
                                            }}
                                            options={epicsList}
                                        />}
                                        </div>
                                        <div>
                                        <label for="user" className="col-form-label pt-2" style={{ width: '170px' }}>{SELECT_SQUAD_MEMBER}</label>
                                        <Select
                                            className="form-control"
                                            placeholder={` ${SELECT_SQUAD_MEMBER}`}
                                            maxMenuHeight={130}
                                            value={selectedUserValue}
                                            onChange={(selectedOption) => {
                                                setSelectedUserValue(selectedOption)
                                                dispatch(actions.userSelected(selectedOption))
                                                dispatch(actions.userDeviceSelected(selectedOption.device_id))
                                            }}
                                            options={userDetails}
                                        />
                                        </div>
                                    </div>
                                    <div class="form-group row pl-2" style={{ marginBottom: '0.5rem', marginTop: "-20px", display: 'grid', gap: '0.5rem', gridTemplateColumns: '1fr 1fr' }}>
                                        <div>
                                        <label for="story" className="col-form-label pt-2" style={{ width: '100px' }}>{STORY_POINTS}</label>
                                        <Select
                                            className="form-control "
                                            placeholder={`Select ${STORY_POINTS}`}
                                            value={selectedStoryValue}
                                            maxMenuHeight={110}
                                            onChange={(selectedOption) => {
                                                if (selectedOption.value === 'Other') {
                                                    setManualValue("Other");
                                                } else {
                                                    setManualValue(null);
                                                    dispatch(actions.storySelected(selectedOption.value))
                                                    setSelectedStoryValue(selectedOption)
                                                }
                                            }}
                                            options={storyPoints}
                                        />
                                        </div>
                                        {/* drop down  for priorityLevel[S.S]  */}
                                        <div>
                                        <label for="story" className="col-form-label pt-2" style={{ width: '100px' }}>{PRIORITY_LEVEL}</label>
                                        <Select
                                            className="form-control "
                                            placeholder={`Select ${PRIORITY_LEVEL}`}
                                            value={selectedPriorityValue}
                                            maxMenuHeight={110}
                                            onChange={(selectedOption) => {
                                                dispatch(actions.prioritySelected(selectedOption.value))
                                                setSelectedPriorityValue(selectedOption)
                                            }}
                                            options={priorityLevels}
                                        />
                                        </div>
                                        {manualValue === 'Other' && (
                                            <>
                                                <input
                                                    className="form-control "
                                                    type="number"
                                                    onChange={(e) => dispatch(actions.storySelected(e.target.value))}
                                                    placeholder={`Enter ${STORY_POINTS} manually`}
                                                />
                                                <br />
                                            </>
                                        )}
                                    </div>
                                    <div style={{ height: 30 }}></div>
                                </div>
                            </div>
                        </div>
                    </div>}
                </Fade>
            </Modal>
        </div >
    );
}