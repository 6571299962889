// WorkflowList.js
import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { getSubStringId } from '../SubStringConvert';
import { useSelector } from 'react-redux';
import { useWebProperties } from '../webProperties';
import ListIcon from '@material-ui/icons/List';
import './reOrder.scss';
import { reOrderWorkflowTemplates } from './Services/reOrderWorkflowTemplates';


const WorkflowList = ({ items, stack, onDragEnd, handleOpen, handleClose }) => {
    const getUser = useSelector(state => state.auth);
    const { WORKFLOW_TEMPLATE, DELETE, ACTION, ACTION_ICON, SUBTASKS, ASSIGNMENT_ORDER } = useWebProperties();

    return (
        <>
            <div className='header-grid'>
                <div className='header-title'></div>
                <div className='header-title'>{WORKFLOW_TEMPLATE}</div>
                <div className='header-title'>{ASSIGNMENT_ORDER}</div>
                <div className='header-title' style={{ textAlign: 'center' }}>{ACTION}</div>
            </div>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided) => (
                        <ul {...provided.droppableProps} ref={provided.innerRef}>
                            {stack.map((tasks, index) => (
                                <Draggable key={tasks.template_id} draggableId={tasks.template_id} index={index}>
                                    {(provided) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            <div className='table-grid'>
                                                <ListIcon />
                                                <div style={{ textTransform: "capitalize", cursor: 'pointer' }}
                                                    data-toggle="tooltip" data-placement="left" title={"Description     :" + tasks.description + "\n\nAssigned By    :" + tasks.username + "\n\nAssigned Date :" + tasks.created_date}>
                                                {<span>{getSubStringId(getUser.user.corp, 3)}-{tasks.template_id}-{tasks.title}</span>}
                                                </div>
                                                <div style={{ textAlign: 'center' }}>{tasks.priority}</div>
                                                <div className="dropdown show">
                                                    {/* eslint-disable-next-line */}
                                                    <a href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-expanded="false">
                                                        <img src="images/common/actionmenu.png" title={ACTION_ICON} alt="logo" className='logo' />
                                                    </a>
                                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuLink" style={{ backgroundColor: 'transparent', border: '0' }}>
                                                        <div>{/* eslint-disable-next-line */}
                                                            {/* {(tasks.status === 'pending') ?
                                                                 <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#76C54E", color: 'white' }} onClick={(event) => handleOpen("modify", index)}>{EDIT}</button> : null} */}
                                                                <button className="dropdown-item badge badge-pill badge-danger text-center" style={{ backgroundColor: 'red' }} onClick={(event) => handleOpen("Delete", index)}>{DELETE}</button>
                                                            {/* {(tasks.status === 'pending') ?
                                                                <button className="dropdown-item badge badge-pill badge-warning text-center" style={{ backgroundColor: "#5cb0bd", color: 'white' }} onClick={(event) => handleOpen("subtask_changeStatus", index)}>{CHANGE_STATUS}</button> : null} */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </ul>
                    )}
                </Droppable>
            </DragDropContext>
            <button type="button" className="btn btn-outline-success save-button" onClick={(event) => { reOrderWorkflowTemplates(stack, SUBTASKS, handleClose) }}>Save</button>
        </>
    );
};

export default WorkflowList;