import { isLoaded, isLoading } from "../actions";
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import { addTasksComment } from "./addTasksComment";


export async function updateSetDoing(dispatch, getUser, subtaskId, mainTaskId, handleClose, message) {
  dispatch(isLoading());
  try { //manageSubtasks.php
    var response = await API.post("get_tasks.php", {
      crop: getUser.corp,
      action: 'setSubtaskDoing',
      task_id: subtaskId,
      maintaskId: mainTaskId,
      "projectId": getUser.projectId
    }, {}, false);
    if (response.status === 'True') {
      addTasksComment(dispatch, getUser, subtaskId, "1", message);
      Alert("success", 'status changed')
      handleClose()
    } else if (response.status === "false") {

      handleClose()
      Alert("warning", response.message)
    }
  } catch (error) {
    Alert('error', error.message);
    handleClose()
  }
  dispatch(isLoaded());
}