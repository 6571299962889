import API from "../../Network/API";
import { isLoading, isLoaded,  employees } from "../action";
import Alert from '../../Alert';


export async function getEmployees(dispatch, getUser) {
  dispatch(isLoading());
  try {
    var response = await API.post("agile_squads.php", {
      "crop": getUser.corp,
      projectId: getUser.projectId,
    }, {}, false);
    if (response.status === 'True') {
      // console.log(response.data)
      dispatch(employees(response.data))
    }
    else {
      dispatch(employees([]))
    }
  } catch (error) {
    Alert('error', error.message);
  }
  dispatch(isLoaded());
}