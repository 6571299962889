import { isLoaded } from "../actions";
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";
import { addCommentUserStory } from "../../Modules/Services/addCommentUserStory";

//Add userstory to sprint
export async function addSprint(sprint_status, assignedto, currentDate, sprintTargetDate, targetDate, taskId, state, dispatch, getUser, handleClose, device_id, assign_to, sprintDesc, title, storyPoints, MODULE, MAINTASK, KANBAN, projectId) {

  if (assignedto === null) {
    Alert(`warning', 'Assign squad member to ${MAINTASK}`);
  }
  else if (targetDate < currentDate && currentDate && targetDate !== '') {
    Alert('warning', 'Select valid date');

  }
  else if (targetDate > sprintTargetDate && currentDate && targetDate !== '') {
    Alert('warning', `Target Date should not exceed ${MODULE} target date`);
  }
  else if (sprint_status === 'commited') {
    Alert('warning', `You cannot add more user stories to this Sprint as it is committed. If you would still like to add more user stories then change the status of the sprint to uncommitted`);
  }

  else if (state.sprintSelected !== "") {
    const message = `This ${MAINTASK} added in ${MODULE} ${(getUser.corp).substring(0, 3).toUpperCase()} - ${sprintDesc}`
    try {
      var response = await API.post("getUpdateSprint.php", {
        crop: getUser.corp,
        action: "sprint_update",
        sprintId: state.sprintSelected.value,
        updatedBy: getUser.empId,
        userStoryId: taskId,
        "projectId": projectId || getUser.projectId,
        targetDate: (targetDate === '') ? sprintTargetDate : targetDate,
        device_id: device_id === null ? state.device_id : device_id,
        sprintDesc: sprintDesc,
        assign_to: assign_to === null ? state.userSelected : assign_to,
        emp_id: getUser.empId,
        title: title,
        storyPoints: storyPoints,
      }, {}, false);
      if (response.status === 'True') {
        addCommentUserStory(dispatch, getUser, taskId, message, "11");
        Alert("success", "UPDATED");
      }
    } catch (error) {
      Alert('error', error.message);
      dispatch(isLoaded());
    }
    handleClose()
  } else {
    Alert("warning", "Something went wrong")
  }
}