import { isLoaded, isLoading } from "../actions";
import Alert from "../../../Common/Alert";
import API from "../../../Common/Network/API";


export async function addEpic(taskId, state, dispatch, getUser, handleClose, projectId) {
  dispatch(isLoading());
  if (state.sprintSelected !== "") {
    try {
      var response = await API.post("getUpdateSprint.php", {
        crop: getUser.corp,
        action: "epic_update",
        epicId: state.epicSelected.value,
        updatedBy: getUser.empId,
        userStoryId: taskId,
        "projectId": projectId || getUser.projectId
      }, {}, false);
      if (response.status === 'True') {
        Alert("success", "UPDATED");
      }
    } catch (error) {
      Alert('error', error.message);
      dispatch(isLoaded());
    }
    handleClose()
  } else {
    Alert("warning", "Something went wrong")
  }
}
