import API from "../../Network/API";
import { isLoading, isLoaded,  modules } from "../action";
import Alert from '../../Alert';


// for getting modules
export async function getModules(dispatch, getUser) {
  dispatch(isLoading());
  try {

     // Future Sprints Name list witout epic by -->SS -->02 on version 1.0.6 start
     var response = await API.post("getUpdateSprint.php", {
      "crop": getUser.corp, 
      "action": "getModules",
      "projectId": getUser.projectId,
    }, {}, false);
      // Future Sprints Name list witout epic by -->SS -->02 on version 1.0.6 end
      // console.log(response)
    if (response.status === 'True') {
      dispatch(modules(response.data))
    }
    else {
      dispatch(modules([]))
    }
  } catch (error) {
    Alert('error', error.message);
  }
  dispatch(isLoaded());
}