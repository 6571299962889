import {
    isLoaded,
    isLoading,
    todo,
    doing,
    done,
    roadblock,
    activeSprint,
    todoFilter,
    doingFilter,
    doneFilter,
    roadblockFilter,
  } from "../actions";
import API from "../../../Common/Network/API";
import store from "../../../Common/Store";
import Alert from "../../../Common/Alert";
import { getToDoUserStories } from "./getToDoUserStories";
import { getRoadBlockUserStories } from "./getRoadblockUserStories";
import { getDoneUserStories } from "./getDoneUserStories";
import { getInvolvedEmployees } from "./getInvolvedEmployees";

export async function getActiveSprints(dispatch, getUser, refresh) {
    if (refresh) { dispatch(isLoading()); }
    try {
      // Current Active Sprint Name with epic by -->GNK -->01 on version 1.0.4 start
      // var response = await API.post("getSprints.php", {
      //   "crop": getUser.corp,
      //   "userType": getUser.role,
      //   "empId": getUser.empId,
      //   "action": "getActiveSprint"
      // }, {}, false);
      // Current Active Sprint Name with epic by -->GNK -->01 on version 1.0.4 end
  
      // Current Active Sprint with out epic by -->SS -->01 on version 1.0.6 start
      var response = await API.post(
        "getUpdateSprint.php",
        {
          crop: getUser.corp,
          action: "get_sprints",
          projectId: getUser.projectId,
        },
        {},
        false
      );
      // Current Active Sprint with out epic by -->SS -->01 on version 1.0.6 end
      if (response.status === "True") {
        const moduleId = response.data[0].moduleId;
        // console.log( response.data[0])
        store.dispatch(activeSprint(response.data[0]));
        getToDoUserStories(dispatch, getUser, moduleId, refresh);
        getRoadBlockUserStories(dispatch, getUser, moduleId, refresh);
        getDoneUserStories(dispatch, getUser, moduleId, refresh);
        getInvolvedEmployees(dispatch, getUser, moduleId, refresh);
      } else {
        store.dispatch(todo([]));
        store.dispatch(doing([]));
        store.dispatch(todoFilter([]));
        store.dispatch(doingFilter([]));
        store.dispatch(roadblock([]));
        store.dispatch(roadblockFilter([]));
        store.dispatch(activeSprint([]));
        store.dispatch(done([]));
        store.dispatch(doneFilter([]));
      }
    } catch (error) {
      Alert("error", error.message);
    }
    dispatch(isLoaded());
  }