export const CONSOLIDATED_TODO_TO = "CONSOLIDATED_TODO_TO"
export const CONSOLIDATED_TODO_BY = "CONSOLIDATED_TODO_BY"
export const PRIVATE_TODO = "PRIVATE_TODO"
export const IS_LOADING = 'IS_LOADING'
export const IS_LOADED = 'IS_LOADED'

export const consolidatedToDoTo = (consolidatedToDoTo) => {
    return {
        type: CONSOLIDATED_TODO_TO,
        payload: consolidatedToDoTo
    };
};
export const consolidatedToDoBy = (consolidatedToDoBy) => {
    return {
        type: CONSOLIDATED_TODO_BY,
        payload: consolidatedToDoBy
    };
};
export const privateToDo = (privateToDo) => {
    return {
        type: PRIVATE_TODO,
        payload: privateToDo
    };
};
export function isLoading() {
    return { type: IS_LOADING };
}
export function isLoaded() {
    return { type: IS_LOADED }
}